<template>
  <div>
    <router-link class="come-back" to="/admin/social_network">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.params.id ? 'Редактирование социальной сети' : 'Создание социальной сети' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createOnkPartners" ref="createOnkPartners">
        <div class="content-sb">
          <p>Ссылка</p>
          <input placeholder="Введите ссылку" v-model="form.link" type="text" name="link" class="input blue">
        </div>
        <div class="content-sb">
          <p>Порядок<span v-if="!$route.params.id">*</span></p>
          <input :required="!$route.params.id" placeholder="Введите порядок" v-model="form.order" type="number" name="order" class="input blue">
        </div>
        <div v-if="$route.params.id" class="content-sb">
          <p>Старый документ</p>
          <img target="_blank" :src="`${form.icon}`">
        </div>
        <div class="content-sb">
          <p>Документ<span v-if="!$route.params.id" >*</span></p>
          <input :required="!$route.params.id"  type="file" name="icon" @change="formFile" class="input blue">
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/social_network" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'SocialNetwork',
  data () {
    return {
      file: '',
      form: this.$route.query.type === 'edit' ? this.$store.getters.getSocialNetworkDetails : {
      }
    }
  },
  watch: {
    '$store.getters.getSocialNetworkDetails' () {
      const data = this.$store.getters.getSocialNetworkDetails
      this.form = data
    }
  },
  methods: {
    formFile ($event) {
      this.file = $event.target.files[0]
    },
    createOnkPartners () {
      const formData = new FormData()
      if (!this.$route.params.id) {
        formData.append('icon', this.file)
      }
      for (var key in this.form) {
        if (key === 'icon') {
          if (this.file !== '') formData.append('icon', this.file)
        } else if (this.form[key].length > 0) {
          formData.append(key, this.form[key])
        }
      }
      // if (this.$route.query.type === 'edit') {
      if (this.$route.params.id) {
        this.$store.dispatch('editSocialNetwork', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('createSocialNetwork', formData)
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      // && this.$store.getters.getSocialNetworkDetails === null) {
      this.$store.dispatch('setSocialNetworkDetails', this.$route.params.id)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
